<template>
  <div class="dashboard">
    <el-row :gutter="20">
      <el-col :span="4">
        <el-card shadow="never">
          <div class="summaryCount">{{summary.articleCount}}</div>
          <div class="summaryLabel">心理课堂</div>
        </el-card>
      </el-col>
      <el-col :span="4">
        <el-card shadow="never">
          <div class="summaryCount">{{summary.medicineCount}}</div>
          <div class="summaryLabel">叮叮服药</div>
        </el-card>
      </el-col>
      <el-col :span="4">
        <el-card shadow="never">
          <div class="summaryCount">{{summary.memberCount}}</div>
          <div class="summaryLabel">系统用户</div>
        </el-card>
      </el-col>
      <el-col :span="4">
        <el-card shadow="never">
          <div class="summaryCount">{{summary.psychologicalCount}}</div>
          <div class="summaryLabel">心理测验</div>
        </el-card>
      </el-col>
      <el-col :span="4">
        <el-card shadow="never">
          <div class="summaryCount">{{summary.responseCount}}</div>
          <div class="summaryLabel">康复反馈</div>
        </el-card>
      </el-col>
      <el-col :span="4">
        <el-card shadow="never">
          <div class="summaryCount">{{summary.treeHoldCount}}</div>
          <div class="summaryLabel">心理树洞</div>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20" style="margin-top:20px">
      <el-col :span="12">
        <el-card shadow="never">
          <v-chart class="chart" :option="article" />
        </el-card>
        <div ></div>
      </el-col>
      <el-col :span="12">
        <el-card shadow="never">
          <v-chart class="chart" :option="medicine" />
        </el-card>
        <div ></div>
      </el-col>
    </el-row>
    <el-card shadow="never" style="margin-top:20px">
      <div slot="header">
        <span>康复反馈</span>
      </div>
      <div>
        <el-row :gutter="10">
          <el-col :span="6">
            <el-table border v-if="r_response" :data="r_response" ref="responseTable" style="width: 100%" size="mini" max-height="250" highlight-current-row @current-change="changeCurrentResponse">
              <el-table-column label="名称">
                <div slot-scope="scope">
                  <div>{{scope.row.testName}}</div>
                  <div>
                    <el-row :gutter="10">
                      <el-col :span="8">平均得分：{{scope.row.averageScore}}</el-col>
                      <el-col :span="8">提交数：{{scope.row.submitCount}}</el-col>
                      <el-col :span="8">用户数：{{scope.row.memberCount}}</el-col>
                    </el-row>
                  </div>
                </div>
              </el-table-column>
            </el-table>
          </el-col>
          <el-col :span="18">
            <el-table v-if="currentResponse" border :data="findResponse()" style="width: 100%" size="mini" max-height="250">
              <el-table-column sortable prop="memberName" label="患者姓名"></el-table-column>
              <el-table-column sortable prop="stage" label="阶段"></el-table-column>
              <el-table-column sortable prop="score" label="得分"></el-table-column>
              <el-table-column sortable prop="createTime" label="时间"></el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
    </el-card>
    <el-card shadow="never" style="margin-top:20px">
      <div slot="header">
        <span>心理测验</span>
      </div>
      <div>
        <el-row :gutter="10">
          <el-col :span="6">
            <el-table border :data="psychological" ref="psychologicalTable" style="width: 100%" size="mini" max-height="250" highlight-current-row @current-change="changeCurrentPsychological">
              <el-table-column label="名称">
                <div slot-scope="scope">
                  <div>{{scope.row.testName}}</div>
                  <div>
                    <el-row :gutter="10">
                      <el-col :span="8">平均得分：{{scope.row.averageScore}}</el-col>
                      <el-col :span="8">提交数：{{scope.row.submitCount}}</el-col>
                      <el-col :span="8">用户数：{{scope.row.memberCount}}</el-col>
                    </el-row>
                  </div>
                </div>
              </el-table-column>
            </el-table>
          </el-col>
          <el-col :span="18">
            <el-table v-if="currentPsychological" border :data="findPsychological()" style="width: 100%" size="mini" max-height="250">
              <el-table-column sortable prop="memberName" label="患者姓名"></el-table-column>
              <el-table-column sortable prop="batch" label="批次"></el-table-column>
              <el-table-column sortable prop="score" label="得分"></el-table-column>
              <el-table-column sortable prop="createTime" label="时间"></el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
    </el-card>
    <el-card shadow="never" style="margin-top:20px">
      <div slot="header">
        <span>责任护士</span>
      </div>
      <div>
        <el-row :gutter="10">
          <el-col :span="6">
            <el-table border :data="nurse.textList" ref="nurseTable" style="width: 100%" size="mini" max-height="250" highlight-current-row @current-change="changeCurrentNurse">
              <el-table-column label="护士">
                <div slot-scope="scope">
                  <div>
                    <el-row :gutter="10">
                      <el-col :span="12">{{scope.row}}</el-col>
                      <el-col :span="12">患者数：{{countMember(scope.row)}}</el-col>
                    </el-row>
                  </div>
                </div>
              </el-table-column>
            </el-table>
          </el-col>
          <el-col :span="18">
            <el-table v-if="currentNurse" border :data="findNurse()" style="width: 100%" size="mini" max-height="250">
              <el-table-column sortable prop="outpatientNumber" label="住院号"></el-table-column>
              <el-table-column sortable prop="name" label="患者姓名"></el-table-column>
              <el-table-column sortable prop="gender" label="性别"></el-table-column>
              <el-table-column sortable prop="height" label="身高"></el-table-column>
              <el-table-column sortable prop="weight" label="体重"></el-table-column>
              <el-table-column sortable prop="birthDay" label="生日"></el-table-column>
              <el-table-column sortable prop="hemiplegicSide" label="偏瘫侧"></el-table-column>
              <el-table-column sortable prop="statusStr" label="状态"></el-table-column>
              <el-table-column sortable prop="phone" label="联系方式"></el-table-column>
              <el-table-column sortable prop="emergencyPhone" label="紧急联系方式"></el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
    </el-card>
    <el-card shadow="never" style="margin-top:20px">
      <div slot="header">
        <span>心理树洞</span>
      </div>
      <div>
        <el-row :gutter="10">
          <el-col :span="6">
            <el-table border :data="treeHole.dataList" style="width: 100%" size="mini" max-height="250">
              <el-table-column label="类型">
                <div slot-scope="scope">
                  <div>
                    <el-row :gutter="10">
                      <el-col :span="12">{{scope.row.name}}</el-col>
                      <el-col :span="12">问题数：{{scope.row.value}}</el-col>
                    </el-row>
                  </div>
                </div>
              </el-table-column>
            </el-table>
          </el-col>
          <el-col :span="18">
            <el-table border :data="treeHole.top20" style="width: 100%" size="mini" max-height="250">
              <el-table-column label="精选" width="60" align="center">
                <template slot-scope="scope">
                  <i v-if="scope.row.isHandpick" class="el-icon-star-on" style="color:#fac858;font-size: 16px;"></i>
                </template>
              </el-table-column>
              <el-table-column sortable prop="question" label="问题"></el-table-column>
              <el-table-column sortable label="回答">
                <template slot-scope="scope">
                  {{cutReply(scope.row.reply)}}
                  <el-popover
                    v-if="scope.row.reply"
                    placement="top-start"
                    :title="scope.row.question"
                    width="400"
                    trigger="hover"
                    :content="scope.row.reply">
                    <i slot="reference" class="el-icon-search" style="float:right;margin-top: 5px;cursor: pointer;"></i>
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column sortable prop="likedNum" label="点赞数"></el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
    </el-card>
  </div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from "echarts/components";
import VChart from "vue-echarts";
use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent
]);
export default {
  components: { VChart },
  data(){
    return{
      summary:{},
      currentPsychological:"",
      currentResponse:"",
      currentNurse:"",
      psychological:[],
      r_response:[],
      nurse:[],
      article:{},
      medicine:{},
      treeHole:{},
    }
  },
  methods:{
    init(){
      this.getSummary()
      this.getPsychological()
      this.getArticle()
      this.getMedicine()
      this.getNurse()
      this.getTreeHole()
      this.getResponse()
    },
    getSummary(){
      this.$axios.get('/api/statistics/summary')
      .then(res => {
        this.summary = res.data.data
      })
    },
    getPsychological(){
      this.$axios.get('/api/statistics/psychological')
      .then(res => {
        this.psychological = res.data.data
        this.currentPsychological = this.psychological[0].testName
        this.$refs.psychologicalTable.setCurrentRow(this.psychological[0])
      })
    },
    findPsychological(){
      return this.psychological.find(e => { return e.testName == this.currentPsychological}).eventList
    },
    changeCurrentPsychological(val){
      this.currentPsychological = val.testName
    },
    getResponse(){
      let self = this;
      self.$axios.get('/api/statistics/response')
      .then(res => {
        self.r_response = res.data.data
        self.currentResponse = self.r_response[0].testName
        self.$refs.responseTable.setCurrentRow(self.r_response[0])
      })
    },
    findResponse(){
      return this.r_response.find(e => { return e.testName == this.currentResponse}).eventList2
    },
    changeCurrentResponse(val){
      this.currentResponse = val.testName
    },
    getNurse(){
      this.$axios.get('/api/statistics/nurse')
      .then(res => {
        this.nurse = res.data.data
        this.currentNurse = res.data.data.textList[0]
        this.$refs.nurseTable.setCurrentRow(this.nurse.textList[0])
      })
    },
    findNurse(){
      let index = this.nurse.textList.findIndex(e => { return e == this.currentNurse});
      return this.nurse.valueList[index]
    },
    countMember(name){
      let index = this.nurse.textList.findIndex(e => { return e == name});
      return this.nurse.valueList[index].length
    },
    changeCurrentNurse(val){
      this.currentNurse = val
    },
    getTreeHole(){
      this.$axios.get('/api/statistics/treeHole')
      .then(res => {
        this.treeHole = res.data.data
      })
    },
    getArticle(){
      this.$axios.get('/api/statistics/article')
      .then(res => {
        let series = []
        res.data.data.textList.forEach((e,i) => {
          series.push({value: res.data.data.valueList[i], name: e})
        });
        let option = {
          title: {
            text: "心理课堂",
            left: "center"
          },
          tooltip: {
            trigger: "item",
            formatter: "{b} <br/> {c} ({d}%)"
          },
          series: [
            {
              name: "心理课堂",
              type: "pie",
              radius: "55%",
              center: ["50%", "60%"],
              data: series,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)"
                }
              }
            }
          ]
        }
        this.article = option
      })
    },
    getMedicine(){
      this.$axios.get('/api/statistics/medicine')
      .then(res => {
        let series = []
        res.data.data.textList.forEach((e,i) => {
          series.push({value: res.data.data.valueList[i], name: e})
        });
        let option = {
          title: {
            text: "叮叮服药",
            left: "center"
          },
          tooltip: {
            trigger: "item",
            formatter: "{b} <br/> {c} ({d}%)"
          },
          series: [
            {
              name: "叮叮服药",
              type: "pie",
              radius: "55%",
              center: ["50%", "60%"],
              data: series,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)"
                }
              }
            }
          ]
        }
        this.medicine = option
      })
    },
    cutReply(reply){
      return reply ? reply.slice(0,20)+'...' : null
    }
  },
  mounted(){
    this.init();
  }
}
</script>

<style lang="scss">
.dashboard{
  .el-card__body{
    text-align: center;
    .summaryCount{
      font-weight: bold;
      font-size: 18px;
      color: #409EFF;
    }
    .summaryLabel{
      font-size:12px;
      color: #656565;
    }
  }
  .chart {
    height: 400px;
  }
}
</style>